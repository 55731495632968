import React, { useEffect, useState } from "react";
import "../Styles/Services.css";
import Footer from "./Footer";
import cncmilling from "../Images/cnc-milling.jpeg";
import cncturning from "../Images/cnc-turning.jpeg";
import manualturning from "../Images/manual-turning.jpeg";
import materialexpertise from "../Images/material-expertise.jpeg";
import prototypemachining from "../Images/prototype-machining.jpeg";
import largesmallbatch from "../Images/large-small-batch.jpg";
import outsourcedcapabilities from "../Images/outsourced_capabilities.jpg";
import qualitycontrol from "../Images/quality_control.jpg";

function Services() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <div>
      <div className={`industries ${loaded ? "loaded" : ""}`}>
        <div className="services-intro">
          <h3>
            We work with clients of all types starting from a basic idea all the
            way to production. We specialize in high precision design and
            machining from scratch. Our team uses the industry standard CAD-CAM
            software to precisely program your parts for production. CNC
            Machining delivers superior, precision machined products to your
            specifications.
          </h3>
        </div>
        <div className="industries-container-first">
          <div className="industries-container-item">
            <img src={cncturning} alt="first-industry"></img>
            <div className="industries-container-text">
              <h1>CNC TURNING</h1>
              <ul>
                <li>
                  <strong>
                    High-precision CNC turning for cylindrical components.
                  </strong>
                </li>
                <li>
                  <strong>Multi-axis turning for intricate geometries.</strong>
                </li>
                <li>
                  <strong>Broaching capabilities</strong>
                </li>
                <li>
                  <strong>4.5" Bore diameter</strong>
                </li>
              </ul>
            </div>
          </div>
          <div className="industries-container-item">
            <img src={cncmilling} alt="second-industry"></img>
            <div className="industries-container-text">
              <h1>CNC MILLING</h1>
              <ul>
                <li>
                  <strong>
                    Utilizing cutting-edge CNC technology for precise and
                    complex machining tasks.
                  </strong>
                </li>
                <li>
                  <strong>
                    Multi-axis milling capabilities for intricate and
                    high-tolerance components.
                  </strong>
                </li>
              </ul>
            </div>
          </div>
          <div className="industries-container-item">
            <img src={manualturning} alt="third-industry"></img>
            <div className="industries-container-text">
              <h1>MANUAL TURNING</h1>
              <ul>
                <li>
                  <strong>
                    Skilled operators for traditional turning processes.
                  </strong>
                </li>
                <li>
                  <strong>
                    Ideal for custom or low-volume turning projects or repairs
                  </strong>
                </li>
                <li>
                  <strong>6" Bore Diameter</strong>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="industries-container-second">
          <div className="industries-container-item">
            <img src={prototypemachining} alt="fourth-industry"></img>
            <div className="industries-container-text">
              <h1>PROTOTYPE MACHINING</h1>
              <ul>
                <li>
                  <strong>
                    Specialized in prototyping to bring design concepts to life
                    quickly.
                  </strong>
                </li>
                <li>
                  <strong>
                    Iterative milling processes for refining and optimizing
                    prototypes.
                  </strong>
                </li>
              </ul>
            </div>
          </div>
          <div className="industries-container-item">
            <img src={largesmallbatch} alt="fifth-industry"></img>
            <div className="industries-container-text">
              <h1>LARGE & SMALL BATCH PRODUCTION</h1>
              <ul>
                <li>
                  <strong>
                    Handle both large-scale production runs and small-batch
                    orders.
                  </strong>
                </li>
                <li>
                  <strong>
                    Efficient setup and machining processes for cost-effective
                    production.
                  </strong>
                </li>
              </ul>
            </div>
          </div>
          <div className="industries-container-item">
            <img src={materialexpertise} alt="sixth-industry"></img>
            <div className="industries-container-text">
              <h1>MATERIAL EXPERTISE</h1>
              <ul>
                <li>
                  <strong>
                    Proficient in milling a variety of materials, including
                    aluminum, steel, titanium, and exotic alloys.
                  </strong>
                </li>
                <li>
                  <strong>
                    Customized tooling solutions for different material
                    requirements.
                  </strong>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="industries-container-third">
          <div className="industries-container-item">
            <img src={qualitycontrol} alt="seventh-industry"></img>
            <div className="industries-container-text">
              <h1>QUALITY CONTROL</h1>
              <ul>
                <li>
                  <strong>
                    Various quality control processes ensure customer
                    satisfaction
                  </strong>
                </li>
                <li>
                  <strong>In-process and post machining QC</strong>
                </li>
                <li>
                  <strong>Multistage inspection reports</strong>
                </li>
                <li>
                  <strong>CMM validation</strong>
                </li>
                <li>
                  <strong>Post coating and finishing QC</strong>
                </li>
              </ul>
            </div>
          </div>
          <div className="industries-container-item">
            <img src={outsourcedcapabilities} alt="eighth-industry"></img>
            <div className="industries-container-text">
              <h1>OUTSOURCED CAPABILITIES</h1>
              <ul>
                <li>
                  <strong>
                    When your project requires some extra finishing work
                  </strong>
                </li>
                <li>
                  <strong>Laser cutting & etching</strong>
                </li>
                <li>
                  <strong>Waterjet cutting</strong>
                </li>
                <li>
                  <strong>Wire EDM Services</strong>
                </li>
                <li>
                  <strong>3D Printing</strong>
                </li>
                <li>
                  <strong>Fabrication</strong>
                </li>
                <li>
                  <strong>Anodizing and Broaching</strong>
                </li>
                <li>
                  <strong>Chrome and Nickel plating</strong>
                </li>
                <li>
                  <strong>Gear cutting</strong>
                </li>
                <li>
                  <strong>Painting and powder coating</strong>
                </li>
                <li>
                  <strong>Heat treat and nitriding</strong>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="contact-us">
          <h1>GET IN TOUCH</h1>
          <div className="get-in-touch">
            <div className="address-sec">
              <h1>CONTACT INFO</h1>
              <p>
                <strong>Windsor Precision Works</strong>
                <br></br>6240 Hawthorne Drive
                <br></br>Windsor, ON N8T 1J9
                <br></br>Canada
                <br></br>
                <br></br>+1 519 800-8080
                <br></br>
                <a href="mailTo:info@precisionworks.ca">
                  info@precisionworks.ca
                </a>
              </p>
            </div>
            <div className="container-map">
              <h1>LOCATE US</h1>
              <iframe
                title="Google Maps Locator"
                height="300cm"
                width="100%"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=6240%20Hawthorne%20Drive+(Windsor%20Precision%20Works)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Services;
