import React from "react";
import "../Styles/Footer.css";

function Footer() {
  return (
    <footer>
      <div className="footer-content">
        <p>© 2024 Windsor Precision Works. All rights reserved.</p>
        <ul className="footer-links">
          <li>
            <a href="/terms">Terms of Service</a>
          </li>
          <li>
            <a href="/privacy">Privacy Policy</a>
          </li>
          <li>
            <a href="/sitemap.xml">Sitemap</a>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
